$black: #000000;
$fontSizeTitle: 25px;
$fontSizeSubtitle: 18px;
$fontSizeParagraph: 16px;

a {
    text-decoration: none;
    p {
        color: $black;
    }
}
.title {
    font-size: $fontSizeTitle !important;
    margin: 20px 0px !important;
    font-weight: bold !important;
}
.subtitle {
    font-size: $fontSizeSubtitle !important;
    margin: 20px 0px !important;
    font-weight: bold !important;
}
.paragraph {
    font-size: $fontSizeParagraph !important;
    margin: 10px 0px !important;
    &.text-red{
        color:red;
    }
}
.logo-main {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 1101;
    display: flex;
    img {
        margin: auto;
    }
}
.button-login {
    background-color: white;
    color: black;
    width: 130px;
    padding: 5px 40px;
    border-radius: 5px;
    font-weight: bold;
}

.button-black,
.button-red {
    color: white !important;
    min-width: 40px !important;
    padding: 7px 40px !important;
    border-radius: 5px !important;
    margin: auto !important;
    text-transform: unset !important;
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed !important;
    }
}
.button-black {
    background-color: black !important;
}

.icon-button-black,
.icon-button-red {
    color: white !important;
    min-width: 40px !important;
    padding: 7px 40px !important;
    border-radius: 5px !important;
    text-transform: unset !important;
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed !important;
    }
}
.icon-button-black {
    background-color: black !important;
}

.button-red {
    background-color: red !important;
    margin-top: 20px;
}

.button-signup {
    color: white;
    padding: 5px 40px;
    width: 130px;

}
.button-bars {
    margin: 10px;
    svg {
        font-size: 40px;
    }
}
.button-subscribe {
    width: 100%;
    margin: auto;
    color: black;
    text-decoration: underline;
    text-align: center;
    display: block;
}
.button-access {
    background-color: black !important;
    color: white !important;
    min-width: 40px !important;
    padding: 5px 40px !important;
    border-radius: 5px !important;
    margin: auto !important;
    text-transform: unset !important;
}

.form {
    display: flex;
    flex-direction: column;
    background-color: #e5e5e5;
    padding: 50px;
    div,
    a {
        margin-bottom: 10px;
        input {
            background-color: white;
        }
    }
    a {
        text-align: right;
        margin-bottom: 20px;
        color: #3f51b5;
        text-decoration: underline;
    }
}
.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

table{
    thead{
        background-color: black;
        tr{
            th{
                color: white!important;
                padding: 10px !important;
            }
        }
       
    }
}

.link-cart{

    svg{
        color: white;
        margin: 0 auto;
        width: 130px;
    
    }
}

.link-user{
    width: 130px;
    color: white;
    font-size: $fontSizeSubtitle;

}

.MuiContainer-root {
    min-height: 700px;
}